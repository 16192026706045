import { MainSlide } from '../components';
import { RedA } from '../../../components';

export const ApolloStories = () => {
  return (
    <MainSlide
      video
      style={{
        backgroundColor: 'black'
      }}
      icon={() => <RedA />}
      title='APOLLO STORIES'
      description={`
       A catalyst for learning and action, Apollo Stories is for educators and students to engage creatively and collaboratively in arts education and creation for a more just and truthful future.
      `}
    />
  )
}
