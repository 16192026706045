import { Grid, Link, Typography } from '@mui/material'
import { Logo, Footer } from '../components'

export const PedagogyPage = () => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100vw',
        maxWidth: '100%',
      }}
    >
      <Grid
        item
        p={4}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: 80,
          width: 80,
          alignSelf: 'flex-start',
        }}
      >
        <Logo variant="redOnWhite" />
      </Grid>
      <Grid
        item
        sx={(theme) => ({
          background: theme.palette.secondary.main,
          [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
          },
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        })}
      >
        <img
          src="https://apolloclassroom.org/wp-content/uploads/2022/09/apollostories-btwam-pedagogy-diagram2.png"
          alt='Silhouette of person writing "PEDAGOGY"'
        />
      </Grid>
      <Grid
        px={4}
        my={16}
        sx={(theme) => ({
          maxWidth: '100%',
          [theme.breakpoints.up('md')]: {
            maxWidth: '900px',
            px: 20,
          },
        })}
      >
        <Grid item>
          <Typography variant="h3" fontSize="4rem" pb={8}>
            Apollo Stories Pedagogical Framework
          </Typography>

          <Typography variant="body1" pb={4}>
            We seek to catalyze the intellectual and artistic development of
            teenagers as a key pedagogical opportunity to spark, reframe, and
            invigorate civic conversation and cultural participation around some
            of the most critical issues of our time.
          </Typography>

          <Typography variant="body1" pb={4}>
            “<b>Dialogic</b>: relating to or in the form of dialogue.” - Oxford
            Languages
          </Typography>

          <Typography variant="body1" pb={4}>
            We believe that true dialogue requires mutual respect and
            cooperation to develop the deep listening, learning, and
            understanding that must be present in “authentic” educational
            spaces. We have done our best to construct this space to reflect
            that.
          </Typography>

          <Typography variant="body1" pb={4}>
            The third chapter of Paulo Feire's <i>Pedagogy of the Oppressed</i>{' '}
            is devoted to a{' '}
            <Link
              href="https://en.wikipedia.org/wiki/Dialogic"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: (t) => t.palette.secondary.main,
              }}
            >
              dialogics
            </Link>{' '}
            – "the essense of education as the practice of freedom" – in which{' '}
            <b
              style={{
                fontWeight: 600,
              }}
            >
              {' '}
              dialogue involves a radical nteraction between reflection and
              action
            </b>
            . We at The Apollo believe that the creation and facilitation of
            true dialogue from student to student, classroom to classroom, and
            youth to the broader culture adds tremendous value and true
            opportunity for educational spaces to become launching grounds for
            long-term civic and cultural engagement.
          </Typography>

          <Typography variant="body1" pb={4}>
            Our hope is to partner with educators and youth to reflect and act
            to shape a better tomorrow.
          </Typography>

          <Typography variant="body1" pb={4}>
            To do so, Apollo Stories launches with the staged and filmed
            productions of Between the World and Me to facilitate a national,
            youth-led conversation about anti-Black racism and its impacts.
          </Typography>
        </Grid>

        <Grid
          item
          sx={(theme) => ({
            width: '100vw',
            height: 'calc(100vw * 315 / 560)',
            ml: -20,
            [theme.breakpoints.down('md')]: {
              width: '100%',
              height: 'calc(100% * 315px / 560px)',
              ml: 0,
            },
          })}
        >
          <iframe
            style={{
              width: '100%',
              height: '100%',
              border: 0,
            }}
            src="https://www.youtube.com/embed/bu5UJ0Plb-U"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Grid>

        <Grid item>
          <Typography variant="h3" fontSize="4rem" py={8}>
            Learning Pathway
          </Typography>

          <Typography variant="body1" pb={4}>
            The Apollo believes in a model that encourages those speaking to
            activate others to both critically respond and to activate
            themselves as leaders toward awareness and change by responding to
            “voice” with “voice” and “action.”
          </Typography>

          <Typography variant="body1" pb={16}>
            Young people today utilize multiple methods of communication that
            simply didn’t exist in past decades. With a wish to be informed, to
            tell stories, and to present that information in ways that are
            accessible to large and diverse audiences, young people particularly
            hunger for truth and authentic engagement.
          </Typography>

          <img
            src="https://apolloclassroom.org/wp-content/uploads/2022/10/195927558-24b1a100-0406-4791-b88e-889860e84f77.png"
            alt="pedagogy diagram"
          />

          <Typography variant="body1" pt={16} pb={4}>
            By focusing on creating dialogic classrooms and youth-centered
            artistic spaces, students will be encouraged to build on their own
            and others’ ideas. Much as young people have adopted an entirely new
            language around gender, it is time to get to a new way of operating
            and seeing one another. The importance of having young people
            speaking to and inspiring other young people to become active civic
            and cultural participants should not be overlooked. No one can
            engage young people around issues and action like other young people
            who are already engaged.
          </Typography>

          <Typography variant="body1" pb={4}>
            Ultimately, this process is designed to facilitate a learning
            journey for educators and youth alike.
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  )
}
