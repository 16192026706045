import { Grid, Link } from '@mui/material'

import redA from './Red-A.svg'
import redAWhiteB from './Red-A-White-Background.svg'
import blackAWhiteB from './Black-A-White-Background.svg'
import whiteA from './White-A.svg'

export const RedA = () => {
  return <img src={redA} alt="logo" />
}

export const RedAWhiteB = () => {
  return (
    <img
      style={{
        background: 'white',
        borderRadius: '50%',
      }}
      src={redAWhiteB}
      alt="logo"
    />
  )
}

export const BlackAWhiteB = () => {
  return (
    <img
      style={{
        background: 'white',
        borderRadius: '50%',
      }}
      src={blackAWhiteB}
      alt="logo"
    />
  )
}

export const WhiteA = () => {
  return <img src={whiteA} alt="logo" />
}

export const Badge = ({
  size = 80,
  variant,
}: {
  size?: number
  variant: 'red' | 'black'
}) => {
  return (
    <Grid
      item
      sx={{
        width: size,
      }}
    >
      {variant === 'red' && <RedAWhiteB />}
      {variant === 'black' && <BlackAWhiteB />}
    </Grid>
  )
}

export const Logo = (props: {
  variant: 'red' | 'redOnWhite' | 'blackOnWhite' | 'white'
  size?: number
}) => {
  const Variant = {
    red: RedA,
    redOnWhite: RedAWhiteB,
    blackOnWhite: BlackAWhiteB,
    white: WhiteA,
  }[props.variant]

  return (
    <Link href="/">
      <Grid
        item
        sx={{
          width: props.size ?? 80,
          height: props.size ?? 80,
        }}
      >
        {<Variant /> ?? <RedA />}
      </Grid>
    </Link>
  )
}
