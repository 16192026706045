import {createTheme} from "@mui/material";

declare module '@mui/material/styles/createPalette' {
  interface SimplePaletteColorOptions {
    main: string
  }
  interface TypeBackground {
    main: string
  }
}

const colors =  createTheme({ palette: {
  primary: {
    main: '#fff',
  },
  secondary: {
    main: '#f00',
  },
  background: {
    main: '#000'
  },
}})

export const theme = createTheme(colors, {
  typography: {
    h1: {
      fontFamily: 'Swiss',
      textTransform: 'uppercase',
      fontSize: '6.5rem',
      letterSpacing: '1px',
      color: colors.palette.primary
    },
    h2: {
      fontFamily: 'Chronicle',
      fontSize: '2.25rem',
      lineHeight: 1,
      color: colors.palette.primary
    },
    h3: {
      fontFamily: 'Swiss',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      fontSize: '2rem',
    },
    h4: {
      fontFamily: 'Chronicle',
      fontSize: '1.5rem',
      color: colors.palette.primary
    },
    p: {
      fontFamily: 'Chronicle',
      color: colors.palette.primary,
      fontSize: '2rem'
    },
    body1: {
      fontFamily: 'Chronicle',
      color: colors.palette.primary,
      fontSize: '1.5rem'
    },
    body2: {
      fontFamily: 'Chronicle',
      fontSize: '1.25rem',
      color: colors.palette.background
    },
    li: {
      fontFamily: 'Chronicle',
      color: colors.palette.primary,
      fontSize: '1.5rem'
    }
  },

})

