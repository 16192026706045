import {createContext} from "react"

const emptyFellow = {
  name: '',
  quote: '',
  image: {
    alt: '',
    src: ''
  },
  location: '',
  bio: '',
  instagram: '',
  twitter: '',
  facebook: '',
}

export const FellowContext = createContext(emptyFellow)

export const FellowsContext = createContext([])
