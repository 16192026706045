import { LinearProgress, Grid } from "@mui/material"
import {useQuery} from "@tanstack/react-query"
import {useFellows} from "../../hooks"
import {FellowCard} from "./components/FellowCard"
import {FellowContext} from "./context"

export const Fellows = () => {
  const Fellows = useFellows()
  const fellowsQuery = useQuery(
    ['Fellows.getAll'],
    async () => await Fellows.getAll(),
    {
      enabled: !!Fellows,
      keepPreviousData: true
    }
  )

  return <Grid item sx={{
    display: 'flex',
    justifyContent: 'center',
  }}>
    { !fellowsQuery.data && <LinearProgress sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      maxWidth: '100%',
    }}color='secondary' />
    }

    {fellowsQuery.data &&
      <Grid container item spacing={4} sx={{
        maxWidth: 1100,
      }}>
        {
          fellowsQuery.data.sort( (a, b)=> a.fellow_name.split(' ').at(-1) > b.fellow_name.split(' ').at(-1) ).map((fellow, index) => {
            return <FellowContext.Provider
              key={`${fellow.fellow_name}-${index}`}
              value={{
                fellow
              }}>
              <Grid item >
                <FellowCard />
              </Grid>
            </FellowContext.Provider>
          })
        }
      </Grid>
    }
  </Grid>
}

