import { Card, CardContent, CardMedia, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { FellowContext } from "../context"
import { FellowModal } from "./FellowModal"

export const FellowCard = () => {
  const { fellow } = useContext(FellowContext)

  const [isOpen, setOpen] = useState(false)

  const {
    large,
    medium_large,
    medium
  } = fellow.featured_media.sizes

  return <>
    <Card
      raised
      onClick={() => setOpen(true)}
      sx={{
        cursor: 'pointer'
      }}>
      <CardContent sx={{
        textAlign: 'center',
      }}>
        <CardMedia
          sx={{
            borderRadius: 1,
            width:300
          }}
          component="img"
          alt={fellow.featured_media.alt}
          height={300}
          image={
            large?.source_url
            ?? medium_large?.source_url
            ?? medium?.source_url
          }
        />
      </CardContent>

      <CardContent sx={{
        textAlign: 'center',
      }}>
        <Typography variant="body2">
          {fellow.fellow_name}
        </Typography>
        <Typography variant="body2">
          {fellow.location}
        </Typography>
      </CardContent>
    </Card>

    <FellowModal
      open={isOpen}
      handleClose={() => {
        setOpen(false)
      }}/>
  </>
}
