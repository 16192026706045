import { Grid, Typography } from '@mui/material';
import {useQuery} from "@tanstack/react-query"
import {useWpMedia} from "../../../hooks"
import {Link} from 'wouter';

const Splash = ({video}) => {
  const Media = useWpMedia()
  const splashQuery = useQuery(
    [],
    () => Media.getSplashImageURL(),
    {
      enabled: !!Media && video,
      keepPreviousData: true
    }
  )

  return <Grid container sx={{
    width: '100vw',
    height: '100vh',
    maxHeight: '100%',
    position: 'fixed',
    display: 'block',
    zIndex: -1
  }}>{video && !splashQuery.isLoading && splashQuery.data && <Grid style={{
    top: 0,
    left: 0,
    position: 'fixed',
    display: 'block',
    minHeight: '100%',
    minWidth: '100%',
    padding: 0,
    margin: 0,
    zIndex: -1
  }}>
    <video autoPlay loop muted style={{
      maxHeight: '100%',
      minHeight: '100vh',
      minWidth: '100vw',
      objectFit: 'cover'
    }}>
      <source src='https://apolloclassroom.org/wp-content/uploads/2022/10/DLE-Homepage-Montage_10_14_22.mp4' type='video/mp4' />
    </video>
  </Grid>
    }
  </Grid>
}

export const MainSlide = (props) => {
  const {
    video,
    title,
    description,
    descriptionHeader,
    icon,
    image,
    navActions,
    exitAction,
  } = props

  return (
    <>
      {video && <>
        <Splash video={video}/>
        <Grid sx={{
          backgroundColor: props.backgroundColor ?? 'black',
          opacity: '20%',
          width: '100vw',
          height: '100%',
          position: 'fixed',
          zIndex: 0
        }} />
      </>}
      
      <Grid container sx={theme => ({
        backgroundColor: 'transparent',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        maxHeight: '100%',
        width: '100%',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
          padding: 6,
        },
        [theme.breakpoints.down('md')]: {
          padding: 4
        }
      })}>
      
        {icon && <Link href='/'>
          <Grid item sx={{
            width: 80,
            zIndex: 1,
            cursor: 'pointer'
          }}>
            {icon()}
          </Grid>
        </Link>}

        {image && <img
          style={{
            position: 'absolute',
            top: image.padding ? 40 : 4,
            right: 40,
            minWidth: '30vw',
            minHeight: '30vw',
            width: '30vw',
            height: '30vw'
          }}
          src={image.src}
          alt={image.alt}
        />}

        <Grid item sx={theme => ({
          maxWidth: 480,
          position: 'relative',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-end'
          },
          [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
          }
        })}>
          <Typography variant='h1' color='primary' mb={4} sx={{
            lineHeight: '.8',
            filter: video ? 'drop-shadow(1px 1px 1px #666)' : undefined,
          }}>
            {title}
          </Typography>
          {descriptionHeader && (
            <Typography variant='h2' color='primary' pb={2} >
              {descriptionHeader}
            </Typography>
          )}

          {!Array.isArray(description) && (
            <Typography variant='h2' color='primary' fontSize='1.5rem' fontWeight={600} maxWidth={440}
              sx={{
                filter: video ? 'drop-shadow(1px 1px 1px #666)' : undefined,
              }}
            >
              {description}
            </Typography>
          )}
          {Array.isArray(description) && (
            description.map( (line, index) => (
              <Typography
                key={index}
                variant='h2'
                maxWidth={420}
                fontSize='1.4rem'
                pb={index === description.length - 1 ? 0 : 2}
              >
                {line}
              </Typography>
            ))
          )}

        </Grid>
        {(!!navActions?.length || exitAction) &&  <Grid container item
          spacing={4}
          mt={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          {navActions?.map( (action, index) => (
            <Grid item key={index}>
              {action()}
            </Grid>
          ))}
          <Grid item sx={{
            flex: 1,
            textAlign: 'right',
          }}>
            {exitAction && exitAction()}
          </Grid>
        </Grid>
        }
      </Grid>
    </>
  )
}
