import {useTheme} from "@emotion/react"
import { Grid, Link, Typography} from "@mui/material"
import {Accordian, Badge, Footer} from "../../components"
import {useWindowSize} from "../../hooks"
import {Lessons} from "../Home/pages"
import { FellowsSlider } from '../Fellows/FellowsSlider'

export const ProjectPage = () => {
	const theme = useTheme()
	const { width } = useWindowSize()

  return (
    <Grid container sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      maxWidth: '100%',
    }}>
      <Grid item sx={{
        width: '100vw',
        height: '100vh',
      }}>
      <Accordian
        width={width}
        items={[
          {
            component: () => {

              return <>
                <Grid item p={6} sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 3,
                  alignSelf: 'flex-start',
                }}>
                  <Link href='/'>
                    <Badge variant='red' />
                  </Link>
                </Grid>

                <img
								src='https://apolloclassroom.org/wp-content/uploads/2022/08/apollostories-tanehisicoates-btwam-hero.png'
								alt='Ta-Nehisi Coates giving speech'
							/>
              </>
						},
						name: 'Project',
						color: theme.palette.background.main,
					}, {
						component: Lessons,
						name: 'Lessons',
						color: theme.palette.secondary.main,
					}, {
						component: FellowsSlider,
						name: 'Fellows',
						color: theme.palette.background.main,
					}
				]}
			/>
      </Grid>

      <Typography variant='h1' fontSize='4rem' sx={{
        maxWidth: 900,
        alignSelf: 'center',
        textAlign: 'center',
      }}>
        Dive deep into the work of Apollo Master artist-in-residience <Link
          href='https://ta-nehisicoates.com/about/'
          target='_blank'
          rel="noopener noreferrer"
          sx={{
            color: t => t.palette.secondary.main
          }}>Ta-Nehisi Coates</Link>
      </Typography>

      <Grid container item pt={8} sx={{
        width: '100%',
        display: 'flex'
      }}>
        <Grid item sx={{
          width: '50%',
          height: 'auto',
          flexGrow: 0,
        }}>
          <img style={{
            borderTop: 'solid 1px white',
            borderBottom: 'solid 1px white',
            borderRight: 'solid 1px white',
          }}
            src='https://apolloclassroom.org/wp-content/uploads/2022/08/apollostories-tanehisicoates-btwam-authorimage.png'
            alt='Ta-Nehisi Coates outside the apollo'
          />
        </Grid>
        <Grid item p={4} sx={theme => (
          {
            width: '50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              [theme.breakpoints.down('lg')]: {
                fontSize: '.5rem'
              }
          }
        )}>
          <Typography variant='body1' pb={4} sx={theme => (
            {
              maxWidth: 700,
              [theme.breakpoints.down('lg')]: {
                fontSize: '1.2rem'
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '.8rem'
              }
            }
          )}>
            Written in 2015 as a letter to his teenage son, Coates’ award-winning book, Between the World and Me, moves between some of  the biggest questions about American history and ideals to the most intimate concerns of a father for his son.
          </Typography>

          <Typography variant='body1' sx={theme => (
            {
              maxWidth: 700,
              [theme.breakpoints.down('lg')]: {
                fontSize: '1.2rem'
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '.8rem'
              }
            }
          )}>
            In 2017, The Apollo presented a staged reading of the work, directed by the Theater’s Executive Producer,
            {' '}<Link
              href='https://www.apollotheater.org/leadership'
              target='_blank'
              rel="noopener noreferrer"
              sx={{color: t => t.palette.secondary.main}}>
              Kamilah Forbes
            </Link>,
            {' '}and in 2020 The Apollo partnered with HBO for a film released into a world riveted by a pandemic and forced to confront long standing modes of racial bias, hatred, and inequity. All of this is now available to you.

          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' mt={8} mr={16} ml={16} sx={theme => (
            {
              textAlign: 'center',
                orphans: 2,
              [theme.breakpoints.down('lg')]: {
                fontSize: '1.2rem'
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '.8rem'
              }
            }
          )}>
            To approach this complex subject matter, the Apollo Stories Fellows created a powerful framework for a better understanding of our nation’s history and culture  through three key themes
          </Typography>
        </Grid>

        {/*
        <Grid container item pt={4} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Grid item px={3} py={1} sx={{
            border: theme => `solid 3px ${theme.palette.secondary.main}`,
            borderRadius: 8,
            backgroundColor: theme => theme.palette.background.main,
            zIndex: 1,
            marginBottom: -4
          }}>
            <Typography variant='h3' sx={{
              textAlign: 'center'
            }}>
              Themes
            </Typography>
          </Grid>
          <Grid item sx={{
            display: 'flex',
            flexFlow: 'row',
            padding: 4,
            borderRadius: 20,
            backgroundColor: theme => theme.palette.background.main,
            border: theme => `solid 3px ${theme.palette.secondary.main}`
          }}>
            <Typography variant='h1' sx={{
              fontSize: '3rem'
            }}>
              <Link href='#'>
                Race
              </Link>
            </Typography>
            <Typography variant='h1' pl={2} sx={{
              fontSize: '3rem'
            }}>
              <span style={{color: 'red'}}>•</span>
              <Link href='#' pl={2}>
                The Black Body
              </Link>
            </Typography>
            <Typography variant='h1' pl={2} sx={{
              fontSize: '3rem'
            }}>
              <span style={{color: 'red'}}>•</span>
              <Link href='#' pl={2}>
                The American Dream
              </Link>
            </Typography>
          </Grid>
        </Grid>
        */}
      </Grid>
      <Footer />
    </Grid>
  )
}

