import { Button, Grid, Typography } from '@mui/material'
import { Link } from 'wouter'

export const Footer = () => {
  return (
    <Grid
      item
      sx={{
        maxWidth: '100%',
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: 'black',
          borderTop: '1px solid',
          borderTopColor: (theme) => theme.palette.primary.main,
          borderBottom: '1px solid',
          borderBottomColor: (theme) => theme.palette.primary.main,
          padding: (theme) => `${theme.spacing(2)} 0`,
          marginTop: (theme) => theme.spacing(8),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Grid
            item
            sx={{
              padding: (theme) => `0 ${theme.spacing(2)}`,
            }}
          >
            <Link href="/about">
              <Button variant="text" color="primary">
                <Typography variant="h3">About</Typography>
              </Button>
            </Link>

            <Link href="/fellows">
              <Button variant="text" color="primary">
                <Typography variant="h3">Fellows</Typography>
              </Button>
            </Link>

            <Link href="#">
              <Button variant="text" color="primary">
                <Typography variant="h3">Join</Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: (theme) => `${theme.spacing(4)} 0`,
        }}
      >
        <Grid item>
          <Typography variant="body2">
            &copy; Copyright 2022 Apollo Theater
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
