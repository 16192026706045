import { Button, Grid, Link, Typography } from '@mui/material'
import { RedAWhiteB } from '../../components'
import { MainSlide } from '../Home/components'
import participatePNG from '../Participate/PARTICIPATE-IMAGE.png'

export const FellowsSlider = () => {
  return (
    <MainSlide
      icon={() => <RedAWhiteB />}
      image={{
        src: participatePNG,
        alt: 'Fellows',
      }}
      backgroundColor="black"
      title="Fellows"
      description={
        <Typography
          fontWeight={300}
          sx={{
            lineHeight: '1',
          }}
        >
          A cohort of in-school and out-of-school educators from across the
          country, nominated by their colleagues and peers, the Apollo Stories
          Fellows craft the curriculum of the Stories Project.
        </Typography>
      }
      navActions={[
        () => (
          <Grid sx={{}}>
            <Typography variant="body1" pb={1}>
              Meet the inaugural cohort:{' '}
            </Typography>
            <Link href="/fellows">
              <Button
                variant="outlined"
                sx={{
                  '&:hover': {
                    backgroundColor: (t) => t.palette.background.main,
                    borderColor: (t) => t.palette.background.main,
                  },
                }}
              >
                <Typography variant="h3">Explore</Typography>
              </Button>
            </Link>
          </Grid>
        ),
      ]}
    />
  )
}
