import { Logo } from '../../components'
import { MainSlide } from '../Home/components/MainSlide'
import participatePNG from './PARTICIPATE-IMAGE.png'

export const Participate = () => {
  return (
    <MainSlide
      icon={() => <Logo variant="redOnWhite" />}
      image={{
        src: participatePNG,
        alt: 'Participate Image',
      }}
      backgroundColor="black"
      title="Coming Soon!"
    />
  )
}
