import {
  Typography,
} from '@mui/material'
import {Grid} from '@mui/material'

import toolkit from './assets/toolkit.png'
import { Markdown } from '../../../components'


export const GenericSlide = ({ children }) => {
  return <Grid p={8} sx={{
    maxHeight: '100%',
    overflowY: 'auto',
  }}>
    <Markdown>
      {children}
    </Markdown>
  </Grid>
}

export const LessonToolkitSlide = () => {
  return (
    <Grid p={8} sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Grid sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography
          variant='h1'
          fontSize='5rem'>
          Lesson
        </Typography>
        <Typography
          variant='h1'
          fontSize='5rem'>
          Toolkit
        </Typography>
      </Grid>

      <img src={toolkit}
        alt='toolkit'
        height={160}
        width={160}/>

    </Grid>
  )

}
