import { createContext, useContext, useMemo, useState } from 'react'
import { Grid, Typography, useTheme, Link } from '@mui/material'
import { Hamburger, BlackAWhiteB } from '../../components'
import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web'

const MenuContext = createContext({
  isOpen: false,
  setIsOpen: () => {}
})

const MenuItem = ({ label, location, fontSize, external, sx }) => {
  const {
    setIsOpen
  } = useContext(MenuContext)

  return <Link
    href={location}
    target={external ? '_blank' : undefined}
    referrerPolicy={external ? 'no-referrer' : undefined} onClick={() => setIsOpen(false)}>
    { typeof label === 'string' &&
      <Typography
        variant="h1"
        sx={{
          fontSize: fontSize ?? '4rem',
          cursor: 'pointer',
          ...sx
        }}>
        {label}
      </Typography>
    }
    { typeof label !== 'string' && label }
  </Link>
}


export const MainMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme();

  const opacityRef = useSpringRef()
  const opacitySpring = useSpring({
    opacity: isOpen ? 1 : 0,
    ref: opacityRef,
  })

  const displayRef = useSpringRef()
  const displaySpring = useSpring({
    display: isOpen ? 'block' : 'none',
    ref: displayRef
  })

  const [hover, setHover] = useState()

  const refs = useMemo(() => {
    return isOpen
      ? [displayRef, opacityRef]
      : [opacityRef, displayRef]
  }, [opacityRef, displayRef, isOpen])

  useChain(refs)

  return <MenuContext.Provider value={{
    isOpen,
    setIsOpen
  }}>
    <Hamburger
      toggled={isOpen}
      toggle={setIsOpen}
    />
    <animated.div style={{
      minHeight: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      backgroundColor: theme.palette.background.main,
      zIndex: 2,
      ...opacitySpring,
      ...displaySpring,
    }}>
      <Grid container direction='column' sx={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
      }}>
        <Grid item width='100vw' display='flex' sx={{
          alignItems: 'center',
          justifyContent: 'center',
          height: 240,
          width: '100vw',
        }}>
          <Grid sx={{
            backgroundColor: 'red',
            position: 'fixed',
            height: 100,
            width: 240,
            alignSelf: 'flex-start',

          }} />
          <Grid sx={{
            backgroundColor: 'red',
            transform: 'translateX(42px) rotate(.06turn)',
            transformOrigin: 'top right',
            height: '130vh',
            width: '200vw',
            alignSelf: 'flex-start',
            flexShrink: 0,
          }} />
          <Link href='/' onClick={() => setIsOpen(false)}>
            <Grid item sx={{
              cursor: 'pointer',
              position: 'relative',
              marginTop: ({ spacing })=> spacing(4),
              marginBottom: ({ spacing })=> spacing(4),
              zIndex: 3,
              width: '11rem',
            }}
            >
              <div onMouseEnter={ () => setHover(true) } onMouseLeave={ () => setHover(false) }>
                { hover ? <Grid sx={{
                  borderRadius: '50%',
                  height: '11rem',
                  width: '11rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  backgroundColor: theme => theme.palette.background.main,
                  border: '3px solid red'
                }}>
                  <Typography
                    variant="h1"
                    align='center'
                    sx={{
                      fontSize: '4rem',
                      cursor: 'pointer'
                    }}>
                    Home
                  </Typography>
                </Grid> : <BlackAWhiteB />
                }
              </div>
            </Grid>
          </Link>
          <Grid sx={{
            backgroundColor: 'red',
            transform: 'translateX(-42px) rotate(-.06turn)',
            transformOrigin: 'top left',
            flexShrink: 0,
            height: '200vh',
            width: '200vw',
            alignSelf: 'flex-start',
          }} />
        </Grid>
        <Grid item sx={{display: 'flex',flexGrow: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <MenuItem label='Projects' location='/projects' />
          <MenuItem label='About' location='/about' />
          <MenuItem label='Pedagogy' location='/pedagogy' />
          <MenuItem label={<Typography variant='h3' fontSize='4rem' sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}><span>Participate </span><span style={{fontSize: '1rem', marginTop: -14}}>(Coming Soon)</span></Typography>} location='/participate' />
        </Grid>
        <MenuItem label='apollotheater.org' location='https://apollotheater.org' external sx={{
          textTransform: 'uppercase',
          paddingBottom: 10,
          paddingTop: 6,
          fontSize: '2rem',
        }}/>
      </Grid>
    </animated.div>
  </MenuContext.Provider>
}
