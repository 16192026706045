import MuiMarkdown from 'mui-markdown'
import {
  Typography,
  ListItem,
  List,
  Link,
} from '@mui/material'

const muiOverrides = {
  ul: {
    component: ({children}) => <List sx={{
      paddingTop: 0,
      listStyle: 'disc outside none'
    }}>
      {children}
    </List>,
  },
  li: {
    component: ({children}) => <ListItem sx={{
      padding: 0,
      display: 'list-item'
    }}>
      <Typography variant='body1' sx={{
        fontSize: '1rem',
      }}>
        {children}
      </Typography>
    </ListItem>,
  },
  h3: {
    component: ({children}) => <Typography
      variant='h3'
      pb={1}
    >
      {children}
    </Typography>,
  },
  p: {
    component: ({children}) => <Typography variant='body1' sx={{
      fontSize: '1rem',
    }} pb={4}>
      {children}
    </Typography>
  },
  a: {
    component: ({children, href}) => <Link href={href} target='_blank' rel="noopener noreferrer" sx={{
      color: 'red',
      '&:visited': {
        color: 'red'
      }
    }}>
      {children}
    </Link>

  },
  span: {
    component: ({children}) => <Typography variant='body1' fontSize='1rem'>{children}</Typography>
  },
  blockquote: {
    component: ({children}) => <Typography variant='body1' fontSize='1rem' textAlign='center' width={700} marginX='auto'>{children}</Typography>
  }
}

export const Markdown = ({children}) => {
  return (
    <MuiMarkdown overrides={muiOverrides}>
      {children}
    </MuiMarkdown>
  )
}
