import axios from 'axios';
import {FellowsClient} from './fellows-api';
import {LessonsClient} from './lessons-api';
import {MediaClient} from './media-api';

export const AxiosClient = axios.create({
  baseURL: process.env.REACT_APP_WP_BASE_URL,
  timeout: 10000
})

AxiosClient.defaults.headers.common['Authorization'] = `Basic ${window.btoa(
  process.env.REACT_APP_WP_USER
  + ':'
  + process.env.REACT_APP_WP_PASSWORD
)}`


let categories
export const getWordpressClient = async () => {
  if (!categories) {
    const response = await AxiosClient
      .get('/categories', {
        params: {
          _fields: 'id,slug',
        }
      })

    categories = response.data
  }

  const client = {
    fellowsClient: new FellowsClient({
      client: AxiosClient,
      categoryId: categories.find(({ slug }) => slug === 'fellow').id
    }),
    mediaClient: new MediaClient({
      client: AxiosClient
    }),
    lessonsClient: new LessonsClient({
      client: AxiosClient,
      categoryId: categories.find(({ slug }) => slug === 'lesson-plan').id,
      moduleCategoryId: categories.find(({ slug }) => slug === 'lesson-module').id,
      contentCategoryId: categories.find(({ slug }) => slug === 'lesson-module-content').id,
    }),
  }

  return client
}

