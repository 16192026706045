import {Button} from "@mui/material"
import {Link} from "wouter"
import {RedA} from "../../components"
import {MainSlide} from "../Home/components"
import youngPeoplePNG from './assets/YoungPeople.png'
import closeSVG from './assets/close.svg'

export const YoungPeoplePage = () => {
  return <MainSlide
    aboutArrow={'/about'}
    icon={RedA}
    title='Young People'
    image={{
      src: youngPeoplePNG,
      alt: 'Young people in a library.',
      padding: true
    }}
    descriptionHeader={
      `Your Voices Matter.`
    }
    description={[`
    The Apollo is committed to a national cultural movement that centers theideas, voices, perspectives, and action of young people.
    `, `
    Here and now, with the Apollo Stories Project, you can engage with each other and the world to share your voices and mobilize a positive change in classrooms and communities across the country.
    `
    ]}
    exitAction={() => (
      <Link href='/'>
        <Button variant='text'>
          <img src={closeSVG} alt='close' style={{
            height:'40px'
          }}/>
        </Button>
      </Link>
    )}
  />
}
