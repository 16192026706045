import { Page } from "../../components/Page"
import { Fellows } from './Fellows'

export const FellowsPage = () => {

  return (
    <Page
      title='Fellows'
      description={[`
        A cohort of in-school and out-of-school educators from across the country, nominated
        by their colleagues and peers, the Apollo Stories Fellows craft the curriculum of the
        Stories Project.
      `,
        'Meet the inaugural cohort:'
    ]}>

      <Fellows />
    </Page>
  )
}
