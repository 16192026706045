import {Clear, Facebook, Instagram, Language, PlayCircleOutlined, Twitter} from "@mui/icons-material"
import {Button, Card, CardContent, CardMedia, Grid, Link, Modal, Typography} from "@mui/material"
import {useContext, useState} from "react"
import {FellowContext} from "../context"

export const FellowModal = (props) => {
  const {
    fellow
  }= useContext(FellowContext)

  const {
    large,
    medium_large,
    medium
  } = fellow.featured_media.sizes

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Modal
      sx={{
        outline: 'none'
      }}
      open={props.open}
      onClose={props.handleClose}>
      <Card sx={theme => ({
        borderRadius: 4,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          bgcolor: theme.palette.secondary.main,
      })}>
        <CardContent sx={{
          padding: theme => theme.spacing(4)
        }}>
          <Grid container sx={theme => ({
            display: 'flex',
              flexDirection: 'row',
              [theme.breakpoints.up('md')]: {
                width: 850,
              }
          })}>

            <Grid item sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',

            }}>
              <Grid display='flex' sx={{
                maxWidth: 300
              }}>
                {fellow?.video && <><Button variant='text' sx={{
                  height: 80,
                  padding: 0
                }} onClick={() => setIsOpen(true)}>
                  <PlayCircleOutlined sx={{
                    color: 'black',
                    height: 80,
                    width: 80
                  }}/>
                </Button>
                  <Modal open={!!isOpen} onClose={() => setIsOpen(false)} sx={{
                  }}>
                    <div>

                      <Button onClick={() => setIsOpen(false)} sx={{
                        position: 'fixed',
                        top: 20,
                        right: 60,
                        backgroundColor: 'red',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: 'red',
                        },
                        zIndex: 4
                      }}>
                        <Clear sx={{
                          fontSize: 70,
                        }}/>
                      </Button>

                      <video autoPlay controls style={{
                        borderRadius: 4,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        outline: 'none',

                      }}>
                        <source src={fellow.video} type='video/mp4' />
                      </video>
                    </div>
                  </Modal>
                </>
                }

                <Grid>
                  <Typography variant='h1' sx={{
                    fontSize: '3rem',
                    color: ({palette}) => palette.background.main,
                    textDecoration: 'underline',
                    textTransform: 'capitalize',
                    textAlign: 'left'
                  }}>
                    {fellow.fellow_name}
                  </Typography>
                  <Typography variant='body2' sx={{
                    textAlign: '',
                    paddingBottom: theme => theme.spacing(2)
                  }}>
                    {fellow.location}
                  </Typography>
                </Grid>
              </Grid>

              <CardMedia
                component='img'
                sx={{
                  borderRadius: 1,
                  width: 300
                }}
                height={400}
                image={
                  large?.source_url
                    ?? medium_large?.source_url
                      ?? medium?.source_url
                }
                alt={fellow.featured_media.alt}
              />

              <Grid container item sx={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'center',
                paddingTop: theme => theme.spacing(2),
              }}>
                {fellow.twitter && (
                  <Link
                    target='_blank'
                    rel='noopener'
                    href={fellow.twitter}
                    sx={{
                      color:  'black',
                      paddingLeft: theme => theme.spacing(2),
                      paddingRight: theme => theme.spacing(2),
                    }}
                  >
                    <Twitter />
                  </Link>
                )}
                {fellow.facebook && (
                  <Link
                    target='_blank'
                    rel='noopener'
                    href={fellow.facebook}
                    sx={{
                      color:  'black',
                      paddingLeft: theme => theme.spacing(2),
                      paddingRight: theme => theme.spacing(2),
                    }}
                  >
                    <Facebook />
                  </Link>
                )}
                {fellow.instagram && (
                  <Link
                    target='_blank'
                    rel='noopener'
                    href={fellow.instagram}
                    sx={{
                      color:  'black',
                      paddingLeft: theme => theme.spacing(2),
                      paddingRight: theme => theme.spacing(2),
                    }}
                  >
                    <Instagram />
                  </Link>
                )}
                {fellow.website && (
                  <Link
                    target='_blank'
                    rel='noopener'
                    href={fellow.website}
                    sx={{
                      color:  'black',
                      paddingLeft: theme => theme.spacing(2),
                      paddingRight: theme => theme.spacing(2),
                    }}
                  >
                    <Language />
                  </Link>
                )}
              </Grid>
            </Grid>

            <Grid item sx={{
              width: 550,
              maxHeight: 460,
              height: '70vh',
              overflowY: 'scroll',
              marginTop: theme => theme.spacing(5),
              marginBottom: theme => theme.spacing(5),
              paddingLeft: theme => theme.spacing(4),
              paddingRight: theme => theme.spacing(4),
              display: 'flex',
              alignSelf: 'center',
              justifySelf: 'flex-center',
              flexDirection: 'column',
            }}>
              {!!fellow.quote &&
              <Typography
                variant='body2'
                sx={{

                  fontStyle: 'italic',
                }}>
                {fellow.quote}
              </Typography>
              }
              {!!fellow.quote_from &&
                <Typography
                  variant='body2'
                  sx={{
                    fontStyle: 'italic',
                    textAlign: 'right',
                    paddingBottom: theme => theme.spacing(2)
                  }}>
                  - {fellow.quote_from}
                </Typography>
              }
              <Typography
                variant='body2'
                sx={{
                  paddingBottom: theme => theme.spacing(2)
                }}>
                {fellow.bio}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

    </Modal>
  )
}
