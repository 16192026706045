import {
  Button,
  Grid,
  LinearProgress,
  Link,
  List,
  ListItem,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { WhiteA } from '../../components/logos'
import { useLessons, useFellows } from '../../hooks'
import Close from '../../components/Close.png'
import { useEffect, useRef, useState } from 'react'
import MuiMarkdown from 'mui-markdown'

export const LessonPage = (props) => {
  const Lessons = useLessons()
  const lesson = useQuery(
    [`Lessons.getById.${props.id}`],
    () => Lessons.getById(props.id),
    {
      enabled: !!Lessons,
    }
  )

  const Fellows = useFellows()
  const fellow = useQuery(
    [`Fellows.getById.${lesson.data?.fellow_id ?? ''}`],
    () => Fellows.getById(lesson.data.fellow_id)
  )

  const [tab, setTab] = useState('offering')
  const scrollRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [canScroll, setCanScroll] = useState(!!scrollRef.current?.scrollTopMax)

  useEffect(() => {
    if (!scrollRef.current) return
    const scroll = scrollRef.current
    setCanScroll(!!scroll.scrollTopMax)

    const onScroll = () => {
      setScrollTop(`${(100 * scroll.scrollTop) / scroll.scrollTopMax}%`)
    }

    scroll.addEventListener('scroll', onScroll)

    return () => scroll.removeEventListener('scroll', onScroll)
  }, [scrollTop, setScrollTop, setCanScroll, tab, lesson.data, fellow.data])

  if (!lesson.data || !fellow.data) {
    return (
      <Grid
        container
        sx={{
          minHeight: '100vh',
          backgroundColor: 'white',
        }}
      >
        <LinearProgress
          color="secondary"
          sx={{
            width: '100vw',
            maxWidth: '100%',
            position: 'fixed',
            top: 0,
          }}
        />
        <Grid
          container
          item
          sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Typography variant="h1" color="black">
            Loading Lesson...
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        flexWrap: 'nowrap',
      }}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          flexGrow: 0,
          width: '100%',
          alignItems: 'center',
          borderBottom: '1px solid #222',
        }}
      >
        <Grid
          item
          p={3}
          sx={{
            height: 140,
            width: 140,
            backgroundColor: 'red',
            borderRight: '1px solid #222',
          }}
        >
          <WhiteA />
        </Grid>
        <Tabs
          value={tab}
          textColor="secondary"
          onChange={(_, newTab) => {
            scrollRef.current?.scroll({
              top: 0,
            })
            setTab(newTab)
          }}
          sx={{
            display: 'flex',
            flexGrow: 1,
            color: 'black',
            '.MuiTabs-flexContainer': {
              paddingLeft: 8,
            },
            '.MuiButtonBase-root': {
              borderRadius: 2,
              '&:hover': {
                backgroundColor: '#DDD',
                '.MuiTouchRipple-child': {
                  backgroundColor: 'red',
                  color: 'red',
                  opacity: 1,
                },
              },
            },
            '.MuiTab-root': {
              marginRight: 4,
              color: 'black',
            },
            '.Mui-selected': {
              color: 'red',
              '.MuiTypography-h3': {
                lineHeight: 0.9,
                borderBottom: '3px solid red',
                borderRadius: 0,
              },
            },
          }}
        >
          <Tab
            value="offering"
            label={
              <Typography variant="h3" fontSize="2.5rem">
                Offering
              </Typography>
            }
          />
          <Tab
            value="conversation"
            label={
              <Typography variant="h3" fontSize="2.5rem">
                Conversation
              </Typography>
            }
          />
          <Tab
            value="purpose"
            label={
              <Typography variant="h3" fontSize="2.5rem">
                Purpose
              </Typography>
            }
          />
          <Tab
            value="sharing"
            label={
              <Typography variant="h3" fontSize="2.5rem">
                Sharing
              </Typography>
            }
          />
          <Tab
            value="action"
            label={
              <Typography variant="h3" fontSize="2.5rem">
                Action
              </Typography>
            }
          />
        </Tabs>
        <Link href={`/lesson-plans/${props.id}`}>
          <Button
            sx={{
              display: 'flex',
              height: 140,
              width: 140,
              borderRadius: 0,
              alignItems: 'center',
              justifyContent: 'center',
              borderLeft: '1px solid #222',
              '&:hover': {
                backgroundColor: '#ddd',
              },
              '.MuiTouchRipple-child': {
                backgroundColor: 'red',
                color: 'red',
                opacity: 1,
              },
            }}
            onClick={() => {}}
            variant="contained"
          >
            <img src={Close} height={40} alt="logo" />
          </Button>
        </Link>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          flexWrap: 'none',
          flex: 1,
          minHeight: 0,
          maxWidth: '100vw',
          width: '100%',
          minWidth: 140,
          color: 'black',
        }}
      >
        <Grid
          item
          sx={{
            flexShrink: 0,
            width: 140,
            borderRight: '1px solid #222',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {canScroll && (
            <Grid
              item
              py={16}
              sx={{
                height: '40%',
                width: '3px',
                backgroundColor: 'black',
                position: 'absolute',
              }}
            >
              <Paper
                sx={{
                  height: 20,
                  width: 20,
                  top: scrollTop,
                  position: 'absolute',
                  borderRadius: '50%',
                  marginLeft: '-8px',
                  backgroundColor: 'red',
                  zIndex: 2,
                }}
              ></Paper>
              <Typography
                variant="h3"
                color="black"
                sx={{
                  position: 'absolute',
                  bottom: '-80px',
                  right: '-30px',
                  transformOrigin: 'center',
                  transform: 'rotate(.25turn)',
                }}
              >
                Scroll
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          p={16}
          ref={scrollRef}
          sx={{
            flex: 1,
            maxHeight: '100%',
            flexWrap: 'none',
            overflowY: 'auto',
            '.h2 + p': {
              marginTop: 0,
              paddingTop: 0,
            },
            '.h3 + p, .h3 + h1, .h3 + ul, .h3 + .blockquote p': {
              marginTop: 0,
              paddingTop: 0,
            },
            '.h1 strong': {
              color: 'red',
              fontWeight: 300,
            },
            '.h1 em': {
              color: 'red',
              paddingRight: 1,
              fontWeight: 300,
            },
          }}
        >
          <MuiMarkdown
            overrides={{
              h3: {
                component: (props) => (
                  <Typography
                    variant="body1"
                    color="#777"
                    className="h3"
                    sx={{
                      fontSize: '1.3rem',
                      textTransform: 'capitalize',
                      ...(props.children?.[0]?.toLowerCase?.().includes('min')
                        ? {
                            display: 'inline-block',
                            marginLeft: 2,
                          }
                        : {}),
                    }}
                  >
                    {props.children}
                  </Typography>
                ),
              },
              h1: {
                component: (props) => (
                  <Typography
                    variant="h3"
                    color="black"
                    mb={2}
                    className="h1"
                    fontSize="4rem"
                  >
                    {props.children}
                  </Typography>
                ),
              },
              h2: {
                component: (props) => (
                  <Typography
                    variant="h3"
                    px={1}
                    py={0.5}
                    mb={4}
                    mt={1}
                    ml={-2}
                    color="white"
                    className="h2"
                    sx={{
                      display: 'inline-block',
                      borderRadius: 2,
                      backgroundColor: 'red',
                    }}
                  >
                    {props.children}
                  </Typography>
                ),
              },
              p: {
                component: (props) => (
                  <Typography variant="body1" py={1} my={2} color="black">
                    {props.children}
                  </Typography>
                ),
              },
              ul: {
                component: (props) => (
                  <List
                    sx={{
                      marginBottom: 2,
                      paddingLeft: 4,
                      listStylePosition: 'outside',
                    }}
                  >
                    {props.children}
                  </List>
                ),
              },
              li: {
                component: (props) => (
                  <ListItem
                    pt={0}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      color: 'black',
                      padding: 0,
                    }}
                  >
                    <Typography variant="body2" display="inline-block" pt={0.5}>
                      –
                    </Typography>
                    <Typography variant="body1" pt={0} ml={1}>
                      {props.children}
                    </Typography>
                  </ListItem>
                ),
              },
              a: {
                component: ({ children, href }) => (
                  <Link
                    target="popup"
                    onClick={() => window.open(href, '_blank', { popup: true })}
                    rel="noopener noreferrer"
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                      color: 'red',
                      '&:visited': {
                        color: 'red',
                      },
                    }}
                  >
                    {children}
                  </Link>
                ),
              },
              blockquote: {
                component: (props) => (
                  <Grid
                    className="blockquote"
                    sx={{
                      fontSize: '1rem',
                      fontStyle: 'italic',
                    }}
                  >
                    {props.children[0]}
                  </Grid>
                ),
              },
            }}
          >
            {lesson.data?.[tab]}
          </MuiMarkdown>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          flexGrow: 0,
          height: 50,
          borderTop: '1px solid #222',
          width: '100%',
        }}
      >
        <Grid
          item
          px={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: 140,
            borderRight: '1px solid #222',
            backgroundColor: 'black',
          }}
        >
          <Grid>
            <Typography variant="body1" fontSize=".5rem" lineHeight=".9">
              Total Time
            </Typography>
            <Typography variant="h3" color="white" fontSize=".8rem">
              {lesson.data?.total_time}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          pl={8}
          ml={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" color="black" fontSize="1.5rem">
            {lesson.data?.title}
            <span
              style={{
                color: 'red',
              }}
            >
              •
            </span>
            {fellow.data?.fellow_name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
