import { Grid, Typography, SxProps, Theme } from '@mui/material'
import { Footer } from './Footer'
import { Logo } from './logos'

interface PageProps extends React.PropsWithChildren {
  title: string
  description: string[]
  sx: {
    children: SxProps<Theme>
  }
}

export const Page: React.FC<PageProps> = (props) => {
  const { children, title, description } = props

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: '100vw',
      }}
    >
      <Grid
        container
        sx={(theme) => ({
          backgroundColor: 'black',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 0,
          padding: 8,
          [theme.breakpoints.down('sm')]: {
            padding: 4,
          },
        })}
      >
        <Grid
          container
          item
          spacing={2}
          sx={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid
            item
            pb={4}
            sx={{
              alignSelf: 'flex-start',
            }}
          >
            <Logo variant="redOnWhite" />
          </Grid>
          <Grid item sx={{ alignSelf: 'flex-start' }}>
            <Typography variant="h1">{title}</Typography>
          </Grid>

          {!!description &&
            description.map((blurb, index) => (
              <Grid
                item
                sx={{ alignSelf: 'flex-start' }}
                key={`${blurb}-${index}`}
              >
                <Typography variant="h4" sx={{ maxWidth: '800px' }}>
                  {blurb}
                </Typography>
              </Grid>
            ))}
        </Grid>

        {children && (
          <Grid
            item
            pt={8}
            sx={{
              marginTop: (theme) => theme.spacing(4),
              width: '100%',
              flex: 1,
              ...(props.sx?.children ?? {}),
            }}
          >
            {children}
          </Grid>
        )}
      </Grid>
      <Footer />
    </Grid>
  )
}
