import { LessonContext } from "../context"
import { Grid, LinearProgress, Link, Typography } from "@mui/material"
import { useWpMedia} from "../../../hooks"
import { useQuery} from "@tanstack/react-query"
import { useContext, useState} from "react"
import { Badge } from "../../../components/logos"
import { FellowModal } from '../../Fellows/components'
import { FellowContext } from "../../Fellows/context"

export const HeroImage = () => {
  const { lesson, fellow } = useContext(LessonContext)

  const [isOpen, setOpen] = useState(false)

  const Media = useWpMedia()
  const imageQuery = useQuery(
    [`Media.getById.${lesson.mediaId}`],
    () => Media.getImageById(lesson.mediaId),
    {
      enabled: !!Media
    }
  )

  return <Grid container item sx={{
    backgroundImage: imageQuery.data?.src ? `url(${imageQuery.data?.src})` : 'none',
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: theme => theme.spacing(4),
    justifyContent: 'space-between',
  }}>
    {imageQuery.isLoading && (
      <LinearProgress color='secondary' sx={{
        width: '100vw',
        maxWidth: '100%',
        position: 'fixed',
        top: 0
      }}/>
    )}

    <Link href='/'>
      <Grid item sx={{
        alignSelf: 'flex-start',
      }}>
        <Badge variant='red' />
      </Grid>
    </Link>

    <Grid container item sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    }}>
      <Grid item sx={{
        display: 'flex',
      }}>
        <Grid item sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}>
          <Grid item>
            <Typography variant='h3'>
              {lesson.title}
            </Typography>
          </Grid>
          <Grid item sx={{
            display: 'flex',
            cursor: 'pointer'
          }}>
            <FellowContext.Provider
              value={{
                fellow
              }}>
            <Typography variant='body1' onClick={() => setOpen(true)}>
              {fellow.fellow_name}
            </Typography>
            <Typography variant='body1' px={1}>
              •
            </Typography>
            <Typography variant='body1' sx={{
              textDecoration: 'underline'
            }}>
              {lesson.lesson_theme}
            </Typography>

            <FellowModal
              open={isOpen}
              handleClose={() => {
                setOpen(false)
              }}/>
                </FellowContext.Provider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
}
