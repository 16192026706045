import {
  ThemeProvider,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';

import { Routes } from './Routes'
import { theme } from './Theme'


const queryClient =  new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
})

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Routes />
      </QueryClientProvider>
    </ThemeProvider>
  )
}

