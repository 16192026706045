import { Button, Grid, LinearProgress, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Page } from '../../components/Page'
import { useLessons } from '../../hooks'
import { LessonCard } from './components'

const themes = {
  All: {
    about:
      'Browse our database of interactive, project-based lesson plans and activities, created by the Apollo Stories Fellows. Each lesson plan is tagged by theme, project, activity, and appropriate level for ease of navigation.',
  },
  'The American Dream': {
    about:
      'What is the American Dream? Who is it accessible to? And why? The lessons in this section analyze the American Dream as presented by Coates in order to excavate the myths embedded in the Dream’s foundation and realization as it relates to all Americans.',
  },
  'The Black Body': {
    about:
      'In both a harsh eye-opening and a stoic statement of fact to his progeny, Coates details the historic exploitation and brutalization of the Black body. The accompanying lessons from the Apollo Stories Fellows give historical context and guide learners in the examination of the Black body as it relates to the story of America and beyond.',
  },
  Race: {
    about:
      'These lessons explore the subjects of race and racism, and provide resources on how to engage in thoughtful, rich conversations about both.',
  },
}

const selected = {
  backgroundColor: 'red',
}

export const LessonsPage = () => {
  const Lessons = useLessons()
  const lessonsQuery = useQuery([`Lessons.getAll`], () => Lessons.getAll(), {
    enabled: !!Lessons,
  })

  const [filter, setFilter] = useState('All')

  return (
    <Page
      title="Lessons"
      sx={{
        children: {
          paddingTop: 0,
        },
      }}
    >
      {!lessonsQuery.data && (
        <LinearProgress
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            maxWidth: '100%',
          }}
          color="secondary"
        />
      )}
      <Grid
        container
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid
          item
          sx={{
            borderRadius: 20,
            '& .MuiButton-root': {
              marginRight: 3,
              marginBottom: 3,
              '& .MuiTouchRipple-child': {
                backgroundColor: 'black',
              },
              '&:hover': {
                backgroundColor: 'red',
              },
            },
          }}
        >
          <Button
            onClick={() => setFilter('All')}
            sx={{
              borderRadius: '40px',
              border: '2px solid white',
              ...(filter === 'All' ? selected : {}),
            }}
          >
            <Typography variant="h3" px={3}>
              All
            </Typography>
          </Button>

          <Button
            onClick={() => setFilter('Race')}
            py={1}
            sx={{
              borderRadius: '40px',
              border: '2px solid white',
              ...(filter === 'Race' ? selected : {}),
            }}
          >
            <Typography variant="h3" px={3}>
              Race
            </Typography>
          </Button>

          <Button
            onClick={() => setFilter('The Black Body')}
            py={1}
            sx={{
              borderRadius: '40px',
              border: '2px solid white',
              ...(filter === 'The Black Body' ? selected : {}),
            }}
          >
            <Typography variant="h3" px={3}>
              The Black Body
            </Typography>
          </Button>

          <Button
            onClick={() => setFilter('The American Dream')}
            py={1}
            sx={{
              borderRadius: '40px',
              border: '2px solid white',
              ...(filter === 'The American Dream' ? selected : {}),
            }}
          >
            <Typography variant="h3" px={3}>
              The American Dream
            </Typography>
          </Button>
        </Grid>
        {!!themes[filter] && (
          <Grid
            py={4}
            sx={{
              display: 'flex',
            }}
          >
            <Typography variant="body1" color="white">
              {themes[filter].about}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid
        p={0}
        pt={8}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {lessonsQuery.data &&
          lessonsQuery.data
            .filter((lesson) =>
              filter !== 'All' ? lesson.theme === filter : true
            )
            .map((lesson, index) => <LessonCard key={index} {...lesson} />)}
      </Grid>
    </Page>
  )
}
