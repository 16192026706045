import {Button} from "@mui/material"
import {Link} from "wouter"
import {RedA} from "../../components"
import {MainSlide} from "../Home/components"
import aboutPNG from './assets/About.png'
import closeSVG from './assets/close.svg'

export const AboutPage = () => {
  return <MainSlide
    icon={RedA}
    title='Coming Soon!'
    image={{
      src: aboutPNG,
      alt: 'Girl checking phone.',
      padding: true
    }}
    exitAction={() => (
      <Link href='/'>
        <Button variant='text'>
          <img src={closeSVG} alt='close' style={{
            height:'40px'
          }}/>
        </Button>
      </Link>
    )}
  />
}
