import {
  Typography,
  Button,
  Link,
  Grid,
  SxProps,
  Theme
} from '@mui/material';

import { MainSlide } from '../components';
import { Logo } from '../../../components';

const lessonsPNG = require('../static/LESSONS-IMAGE.png');

const Themes = ({sx}: {
  sx: SxProps<Theme>
} ) => {
  return (
    <Grid container item pt={4} sx={{
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-start',
      ...sx
    }}>
      <Grid item >
        <Typography variant='h3' fontSize='1rem' px={1} py={.5} sx={{
          textAlign: 'center',
          backgroundColor: 'black',
          borderRadius: 8,
          display: 'inline-block'
        }}>
          Themes
        </Typography>
      </Grid>

      <Grid item py={1} sx={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}>
        <Typography variant='h1' fontSize='1.5rem'>
          Race
        </Typography>

        <Typography variant='h1' fontSize='1.5rem' px={1} color='black'> • </Typography>

        <Typography variant='h1' fontSize='1.5rem'>
          The Black Body
        </Typography>

        <Typography variant='h1' fontSize='1.5rem' px={1} color='black'> • </Typography>

        <Typography variant='h1' fontSize='1.5rem'>
          The American Dream
        </Typography>

      </Grid>
    </Grid>
  )
}

export const Lessons = () => (
  <MainSlide
    icon={() => <Logo variant='redOnWhite'/>}
    image={{
      src: lessonsPNG,
      alt: 'Lessons Image'
    }}
    backgroundColor='red'
    title='Lessons'
    description={<Grid sx={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Typography fontWeight={300} lineHeight={1}>
        Explore and Utilize a diverse, inclusive,
        topical series of curriculum designed
        by Educators for Educators
        in alignment with the Apollo Theater’s
        pedagogy for transformative action through the arts.
      </Typography>
      <Themes sx={{
        flexGrow: 1
      }}/>
    </Grid>}
    navActions={[
      () => <Link href='/lessons'>
        <Button variant='outlined' sx={{
          '&:hover': {
            backgroundColor: t => t.palette.background.main,
            borderColor: t => t.palette.background.main
          }
        }}>
          <Typography variant='h3'>Explore</Typography>
        </Button>
      </Link>
    ]}
  />
)

