export class FellowsClient {
  constructor({
    client,
    categoryId
  }) {
    this.client = client
    this.categoryId = categoryId
  }

  async getAll() {
    return this.client.get('/posts', {
      params: {
        categories: [this.categoryId],
        _embed: true,
        _fields: [
          'id',
          'slug',
          'acf',
          '_links'
        ]
      }
    }).then(
      res => {
        return res.data.map( data => {
          return {
            ...data.acf,
            featured_media: data._embedded?.['wp:featuredmedia']?.map( (media) => {
              return {
                alt: media.alt_text || `media-${media.title.rendered}`,
                sizes: media.media_details.sizes
              }
            })?.[0]
          }
        })
      }
    )
  }

  async getById(id) {
    const fellow = await this.client
      .get(`/posts/${id}`, {
        params: {
          _embed: true,
          _fields: [
            'id',
            'slug',
            'acf',
            '_links'
          ]
        }
      })
      .then(
        res => {
          return {
            ...res.data.acf,
            featured_media: res.data._embedded?.['wp:featuredmedia']?.map( (media) => {
              return {
                alt: media.alt_text || `media-${media.title.rendered}`,
                sizes: media.media_details.sizes
              }
            })?.[0]
          }
        }
      )

    return fellow
  }
}
