import {
  Grid,
  Typography,
} from "@mui/material"
import {useContext} from "react"
import {Accordian} from "../../../components/Accordian"
import {Markdown} from '../../../components'
import {LessonContext} from "../context"
import {LessonToolkitSlide, GenericSlide} from "./Slides"

export const LessonToolkitAccordian = () => {

  const { lesson } = useContext(LessonContext)

  return (
    <Grid mb={8} sx={{
      display: 'flex',
      justifyContent: 'center',
      width: '100vw',
    }}>
      <Grid px={4} sx={{
        display: 'flex',
        justifyContent: 'center',
        width: 1200,
      }}>
        <Accordian
          hover
          border
          width={1000}
          height={700}
          items={[
            {
              component: LessonToolkitSlide,
                name: 'Lesson Toolkit',
                color: 'black'
            }, {
              component: () => (<Grid container sx={{
                maxHeight: '100%',
              }}>
                <Grid item p={8}>
                  <Typography variant='h3'>Materials Needed</Typography>
                  <Markdown>{lesson.materials_needed}</Markdown>

                  <Typography variant='h3' pt={4} pb={1}>Prework</Typography>
                  <Markdown>{lesson.prework}</Markdown>
                </Grid>
              </Grid>),
              name: 'Materials Needed',
              color: 'black'
            }, {
              component: () => (<Grid container sx={{
                maxHeight: '100%',
              }}>
                <Grid item p={8}>
                  <Markdown>{lesson.objective}</Markdown>
                </Grid>
              </Grid>),
              name: 'Questions & Objectives',
              color: 'black'
            }, {
              component: () => (<>
                <GenericSlide>{lesson.sel_support}</GenericSlide>
              </>),
              name: 'Socio-Emotional Learning',
              color: 'black'
            }, {
              component: () => (<>
                <GenericSlide>{lesson.learning_standards}</GenericSlide>
              </>),
              name: 'Standards',
              color: 'black'
            }
          ]}
        />
      </Grid>
    </Grid>
  )

}
