import { Redirect, Route, Switch } from 'wouter'
import {
  FellowsPage,
  HomePage,
  LessonPage,
  LessonsPage,
  LessonPlanPage,
  Participate,
  PedagogyPage,
  ProjectPage,
  MainMenu,
} from './pages'
import { AboutPage } from './pages/About/AboutPage'
import { EducatorsPage } from './pages/About/EducatorsPage'
import { YoungPeoplePage } from './pages/About/YoungPeoplePage'

export const Routes = () => {
  return (
    <Switch>
      <Route path='/'>
        <MainMenu />
        <HomePage />
      </Route>
      <Route path='/about'>
        <MainMenu />
        <AboutPage />
      </Route>
      <Route path='/about/educators'>
        <MainMenu />
        <EducatorsPage />
      </Route>
      <Route path='/about/young-people'>
        <MainMenu />
        <YoungPeoplePage />
      </Route>
      <Route path='/fellows'>
        <MainMenu />
        <FellowsPage />
      </Route>
      <Route path='/lessons'>
        <MainMenu />
        <LessonsPage />
      </Route>
      <Route path='/lesson-plans/:id'>
        {
          ({ id }) => <>
            <MainMenu />

            <LessonPlanPage id={id} />
          </>
        }
      </Route>
      <Route path='/lessons/:id'>
        {
          ({ id }) => <LessonPage id={id} />
        }
      </Route>
      <Route path='/participate'>
        <MainMenu />
        <Participate />
      </Route>
      <Route path='/pedagogy'>
        <MainMenu />
        <PedagogyPage />
      </Route>
      <Route path='/projects'>
        <MainMenu />
        <ProjectPage />
      </Route>
      <Route path='/:undefined*'>
        <MainMenu />
        <Redirect to='/' />
      </Route>
    </Switch>
  )
}
