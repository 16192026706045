import { Button, Grid } from '@mui/material'
import { Squash } from 'hamburger-react'

export const Hamburger = (props) => {
  return <Grid item sx={ theme => ({
    position: 'fixed',
    zIndex: 3,
    top: 0,
    right: 8,
        [theme.breakpoints.up('md')]: {
          margin: 6
        },
        [theme.breakpoints.down('md')]: {
          margin: 4
        }
  })}>
    <Button variant='text'>
    <Squash
      size={100}
      rounded={true}
      {...props}/>
    </Button>
  </Grid>
}
