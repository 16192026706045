import {
  Grid,
} from '@mui/material';
import {useWindowSize} from '../../hooks';
import {
  ApolloStories,
} from './pages'

export const HomePage = () => {
  const { width, height } = useWindowSize()

  return (
    <Grid sx={{
      width,
      height
    }}>
      <ApolloStories />
    </Grid>
  )
}
