import {Grid, Typography} from "@mui/material"
import {useContext} from "react"
import {LessonContext} from "../context"

export const Quote = () => {
  const { lesson } = useContext(LessonContext)

  return <Grid container item
    display='flex'
    flexDirection='column'
    alignContent='center'
    p={8}
  >
    <Typography variant='h3' textAlign='center' width={700}>
      {lesson.quote}
    </Typography>
    <Typography
      pt={1}
      variant='body1'
      textAlign='right'
      width={700}
      fontSize='1.5rem'
      fontStyle='italic'
    >
      - {lesson.quote_from}
    </Typography>
  </Grid>
}
