import {useQuery} from '@tanstack/react-query';
import {
  useState,
  useEffect,
} from 'react'
import {getWordpressClient} from '../api';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}


export const useWordpress =() => {
  const wpClient = useQuery(
    ['wordpress-client'],
    () => getWordpressClient()
  )

  return wpClient
}

export const useFellows = () => {
  const wordpressClient = useWordpress()

  return wordpressClient.data?.fellowsClient
}

export const useWpMedia = () => {
  const wordpressClient = useWordpress()

  return wordpressClient.data?.mediaClient
}

export const useLessons = () => {
  const wordpressClient = useWordpress()

  return wordpressClient.data?.lessonsClient
}
