import {Button} from "@mui/material"
import {Link} from "wouter"
import {RedA} from "../../components"
import {MainSlide} from "../Home/components"
import educatorsPNG from './assets/Educators.png'
import closeSVG from './assets/close.svg'

export const EducatorsPage = () => {
  return <MainSlide
    aboutArrow={'/about/young-people'}
    icon={RedA}
    title='Educators'
    image={{
      src: educatorsPNG,
      alt: 'Girl checking phone.',
      padding: true
    }}
    description={[
      <span>
        This curated digital learning environment and curriculum created{' '}
        <Link href='/fellows' style={{
          color: 'red'
        }}>by Educators for Educators</Link>
        {' '}is designed to transform the arts into a living,
        breathing mechanism for dialogue and transformative action.
      </span>, <span>
        We invite you to join the <Link href='/pedagogy'>Apollo experience</Link>. Not only do we hope you take advantage of all that you find here, we welcome your ideas and educational
    approaches into this work as well. Together, we can elevate and amplify new
    stories into the world.
      </span>
    ]}
    exitAction={() => (
      <Link href='/'>
        <Button variant='text'>
          <img src={closeSVG} alt='close' style={{
            height:'40px'
          }}/>
        </Button>
      </Link>
    )}
  />
}
