const PROD = window.location.host.split('.')[0] === 'stories'

export class LessonsClient {
  constructor({ client, categoryId, moduleCategoryId, contentCategoryId }) {
    this.client = client
    this.categoryId = categoryId
    this.moduleCategoryId = moduleCategoryId
    this.contentCategoryId = contentCategoryId
  }

  async getAll() {
    const params = {
      categories: [this.categoryId],
      _embed: true,
      _fields: [
        'id',
        'acf',
        '_links', // this gets us the _embedded key from the wordpress API, idk why.
      ],
    }

    const published = (await this.client.get('/posts', { params }))?.data

    const drafts = !PROD
      ? (
          await this.client.get('/posts', {
            params: {
              ...params,
              status: 'draft',
            },
          })
        )?.data
      : []

    return [...published, ...drafts].map((data) => {
      const fellow = data._embedded?.['acf:post'].find(
        ({ id }) => id === data.acf.fellow_ids[0]
      )

      return {
        ...data.acf,
        fellow_id: data.acf.fellow_ids[0],
        id: data.id,
        fellow: {
          ...fellow.acf,
          featured_media: fellow.featured_media,
        },
        featured_media: data._embedded?.['wp:featuredmedia']?.map((media) => {
          return {
            alt: media.alt_text || `media-${media.title.rendered}`,
            sizes: media.media_details.sizes,
          }
        })?.[0],
        status: data.status,
      }
    })
  }

  async getById(id) {
    const lesson = await this.client.get(`/posts/${id}`).then((res) => {
      return {
        ...res.data.acf,
        fellow_id: res.data.acf.fellow_ids[0],
        mediaId: res.data.featured_media,
      }
    })

    return lesson
  }
}
