import {
  Button,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'wouter'
import { useWpMedia } from '../../../hooks'

export const LessonCard = ({
  id,
  title,
  fellow,
  featured_lesson_description,
  featured_media,
  in_school,
  theme,
  suggested_level,
}) => {
  const muiTheme = useTheme()
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))

  const Media = useWpMedia()
  const profilePictureQuery = useQuery(
    [`Media.getImageById(${fellow.featured_media})`],
    async () => Media.getImageById(fellow.featured_media),
    {
      enabled: !!Media && !!fellow.featured_media,
      keepPreviousData: true,
    }
  )

  const tags = [theme, in_school ? 'In School' : 'Out of School']

  return (
    <Grid
      container
      mr={8}
      mb={8}
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        flexBasis: 520,
        width: 700,
        border: 'solid 4px white',
        borderRadius: 1,
        [theme.breakpoints.down('lg')]: {
          marginBottom: theme.spacing(8),
        },
        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
          flexBasis: 440,
        },
      })}
    >
      <Grid
        item
        sx={(theme) => ({
          marginBottom: theme.spacing(4),
        })}
      >
        <CardMedia
          sx={{
            borderRadius: (theme) =>
              `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
            width: '100%',
            height: isMobile ? undefined : 300,
          }}
          component="img"
          alt={featured_media.alt}
          image={featured_media.sizes.large.source_url}
        />{' '}
      </Grid>
      <Grid
        container
        item
        p={isMobile ? 1 : 4}
        pt={0}
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {suggested_level && (
          <Grid
            pb={4}
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              variant="h3"
              color="white"
              align="center"
              sx={{
                padding: '2px 2px 0px 5px',
                borderRadius: '3px',
                backgroundColor: 'black',
              }}
            >
              Level: {suggested_level}{' '}
            </Typography>{' '}
          </Grid>
        )}
        <Grid
          item
          p={0}
          sx={(theme) => ({
            flex: 1,
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'flex-start',
              marginBottom: (t) => t.spacing(2),
            }}
          >
            <CardMedia
              sx={{
                borderRadius: '50%',
                height: 75,
                width: 75,
                flexShrink: 0,
                marginRight: (t) => t.spacing(2),
                marginTop: 1,
                mb: isMobile ? 2 : 0,
              }}
              component="img"
              alt={profilePictureQuery.data?.alt}
              height={100}
              width={100}
              image={profilePictureQuery.data?.src}
            />
            <Grid
              item
              sx={{
                display: 'flex',
                height: '100%',
                lineHeight: 1,
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {title.split(':').map((line, index) => (
                <Typography
                  variant="h3"
                  key={index}
                  fontSize="2.5rem"
                  color="black"
                >
                  {line} {index === 0 && title.split(':').length > 1 && ':'}{' '}
                </Typography>
              ))}{' '}
              <Typography
                variant="h3"
                sx={{
                  color: 'red',
                }}
              >
                BY {fellow.fellow_name}{' '}
              </Typography>{' '}
            </Grid>{' '}
          </Grid>{' '}
          <Typography
            mb={4}
            variant="body1"
            fontSize={isMobile ? '1rem' : '1.2rem'}
            sx={{
              color: 'black',
            }}
          >
            {featured_lesson_description}{' '}
          </Typography>{' '}
        </Grid>
        {tags.length && (
          <Grid item>
            <Typography
              variant="h3"
              color="red"
              fontSize="1.5rem"
              letterSpacing="0"
            >
              Tags: {tags.join(', ')}{' '}
            </Typography>{' '}
          </Grid>
        )}
        <Grid item pt={4}>
          <Link href={`/lesson-plans/${id}`}>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: 'red',
                '&& .MuiTouchRipple-child': {
                  backgroundColor: 'red',
                },
                '&:hover': {
                  borderColor: 'red',
                  color: 'red',
                },
              }}
            >
              <Typography variant="h3"> Explore </Typography>{' '}
            </Button>{' '}
          </Link>{' '}
        </Grid>{' '}
      </Grid>{' '}
    </Grid>
  )
}
