import {
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import { Typography, Grid } from '@mui/material';
import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';

const ACCORDIAN_WIDTH = 70;

const AccordianContext = createContext({
  current: ''
})

const AccordianElement = (props) => {
  const {
    name,
    color,
    index,
    style,
    width,
    height,
    border,
    hover,
    children: Component,
  } = props
  const [isHovered, setHovered] = useState(false)

  const { current, setCurrent, length } = useContext(AccordianContext)

  const isCurrent = useMemo(() => current === name, [current, name])

  const lastPositioning = length - 1 === index ? {
    position: 'absolute',
    right: 0,
  } : {}

  const expandRef = useSpringRef()
  const styles = useSpring({
    ...lastPositioning,
    cursor: !isCurrent ? 'pointer' : 'default',
    width: isCurrent ? '100%' : '0%',
    ref: expandRef
  })

  const contentRef = useSpringRef()
  const contentSpring = useSpring({
    opacity: isCurrent ? 1 : 0,
    maxHeight: '100%',
    ref: contentRef
  })

  const labelSpring = useSpring({
    opacity: isCurrent ? 0 : 1,
    maxHeight: '100%'
  })

  useChain([expandRef, contentRef])

  return <animated.div
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
    onClick={() => setCurrent(name)}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: `${width - (ACCORDIAN_WIDTH * (length - 1)) }px`,
      minWidth: `${ACCORDIAN_WIDTH}px`,
      height: height ?? '100vh',
      backgroundColor: hover && isHovered && !isCurrent ? 'red' : color,
      borderLeft: border ? '1px solid white' : 'none',
      borderRight: border ? '1px solid white' : 'none',
      zIndex: 1,
      ...styles,
    }}>
    {
      !isCurrent && (
        <animated.span style={labelSpring}>
          <Typography variant='h3' color='primary' sx={{
            fontSize: '2.5rem',
            width: '400px',
            transform: 'rotate(90deg)',
            marginLeft: '-10.5rem',
            textAlign: 'center',
            ...style
          }}>{name}</Typography>
        </animated.span>
      )
    }
    {
      isCurrent && <animated.span style={contentSpring}>
        <Component />
      </animated.span>
    }
  </animated.div>

}

export const Accordian = (props) => {

  const {
    items,
    width,
    height,
    border,
    hover
  } = props

  const [current, setCurrent] = useState(items[0].name)

  return <AccordianContext.Provider value={{
    current,
    setCurrent,
    length: items.length
  }}>
    <Grid container sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      width: width ?? '100%',
      height: height ?? '100%',
      border: theme => border ? `solid 1px ${theme.palette.primary.main}` : 'none',
      borderRadius: border ? 1 : 0,
    }}>
      {items.map( (item, index) => (
        <AccordianElement
          hover={hover}
          border={border}
          width={width}
          height={height}
          index={index}
          name={item.name}
          key={index}
          color={item.color}
        >
          {item.component}
        </AccordianElement>
      ))}
    </Grid>
  </AccordianContext.Provider>

}
