import {Clear, PlayArrow} from "@mui/icons-material"
import {Button, Grid, LinearProgress, Link, Modal, Typography} from "@mui/material"
import {Box} from "@mui/system"
import {useQuery} from "@tanstack/react-query"
import {useEffect, useState} from "react"
import {Footer} from "../../components/Footer"
import {ProfilePicture} from "../../components/ProfilePicture"
import {useFellows, useLessons} from "../../hooks"
import {FellowModal} from "../Fellows/components"
import {FellowContext} from "../Fellows/context"
import {
  HeroImage,
  LessonToolkitAccordian,
  Quote
} from "./components"
import {LessonContext} from "./context"

export const LessonPlanPage = ({
  id
}) => {

  const Lessons = useLessons()
  const lessonQuery = useQuery(
    [`Lessons.getById.${id}`],
    () => Lessons.getById(id),
    {
      enabled: !!Lessons
    }
  )

  const Fellows = useFellows()
  const fellowQuery = useQuery(
    [`Fellows.getById.${lessonQuery.data?.fellow_id ?? ''}`],
    () => Fellows.getById(lessonQuery.data.fellow_id),
    {
      enabled: !!Fellows && !!lessonQuery.data
    }
  )

  const [isOpen, setOpen] = useState(false)
  const [isVideoOpen, setVideoOpen] = useState(false)
  useEffect(() => {
    lessonQuery.data && window.scrollTo({ top: 0 })
  }, [lessonQuery.data])

  return (
    <LessonContext.Provider
      value={{
        lesson: {
          id,
          ...(lessonQuery.data || {})
        },
        fellow: fellowQuery.data
      }}>
      <Grid container sx={{
        minHeight: '100vh',
        backgroundColor: 'black',
      }}>
        {(lessonQuery.isLoading || fellowQuery.isLoading) && (
          <LinearProgress color='secondary' sx={{
            width: '100vw',
            maxWidth: '100%',
            position: 'fixed',
            left:0,
            top: 0
          }}/>
        )}
        {(lessonQuery.isLoading || fellowQuery.isLoading) && (
          <Grid container item sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent:  'center',
            alignContent:  'center',
          }}>
            <Typography variant='h1'>
              Loading Lesson Toolkit...
            </Typography>
          </Grid>
        )}

        {lessonQuery.data && fellowQuery.data && (
          <Box sx={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <HeroImage />
            <Quote />
            <Box justifyContent='space-between' display='flex' mb={4} sx={{
              border: '1px solid white',
              width: '100%',
              maxWidth: '1000px'
            }}>
              <ProfilePicture sx={{ borderRadius: 0, height: '100%', width: 'auto', borderRight: '1px solid white'}} />
              <Box sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center'
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}>
                  <Typography variant='h3'textAlign='center'>
                    Walk through the lesson with
                  </Typography>
                  <Button variant='text' onClick={() => setOpen(true)}>
                    <Typography variant='h3' fontSize='4rem' color='red' sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                      }
                    }}>
                      { fellowQuery.data?.fellow_name }
                    </Typography>
                  </Button>
                  <FellowContext.Provider value={{fellow: fellowQuery.data}}>
                    <FellowModal
                      open={isOpen}
                      handleClose={() => {
                        setOpen(false)
                      }}/>
                  </FellowContext.Provider>
                </Box>
              </Box>
              <Button sx={{
                display: 'inline-block',
                backgroundColor: 'red',
                height: '100%',
                width: '150px',
                margin: 0,
                padding: 0,
                borderLeft: '1px solid white'
              }} onClick={() => setVideoOpen(true)}>
                <PlayArrow sx={{
                  height: 150,
                  width: 150,
                  transform: 'scaleY(.9) translateX(-8px)'
                }}
                />
              </Button>
              <Modal open={!!isVideoOpen} onClose={() => setVideoOpen(false)}>
                <div>
                  <Button onClick={() => setVideoOpen(false)} sx={{
                    position: 'fixed',
                    top: 20,
                    right: 60,
                    backgroundColor: 'red',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: 'red',
                    },
                    zIndex: 4
                  }}>
                    <Clear sx={{
                      fontSize: 70,
                    }}/>
                  </Button>

                  <video autoPlay controls style={{
                    borderRadius: 4,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    outline: 'none',

                  }}>
                    <source src={lessonQuery.data?.video} type='video/mp4' />
                  </video>
                </div>

                </Modal>
            </Box>
            <LessonToolkitAccordian />

            <Box alignContent='center' justifyContent='center' display='flex' width='100%'>
              <Link href={`/lessons/${id}`}>
              <Button variant='contained' py={4} sx={{
                borderRadius: '5rem',
                backgroundColor: 'red',
                color: 'white',
                padding: '6px 40px',
              }}>
                <Typography variant='h1' mt={1} fontSize='4.875rem'>
                  Start Lesson
                </Typography>
              </Button>
              </Link>
            </Box>

          </Box>
        )}
      </Grid>
      <Footer />
    </LessonContext.Provider>
  )
}
