export const SIZE_THUMBNAIL = 'thumbnail'
export const SIZE_MEDIUM = 'medium'
export const SIZE_MEDIUM_LARGE = 'medium_large'
export const SIZE_LARGE = 'large'
export const SIZE_FULL = 'full'



export class MediaClient {

  constructor({
    client
  }) {
    this.client = client
  }

  async getImageById(id) {

    return this.client.get(`/media/${id}`).then(
      res => {
        return {
          alt: res.data.alt_text || `media-${id}`,
          src: res.data.media_details.sizes.large?.source_url ??
            res.data.media_details.sizes.medium_large?.source_url ?? res.data.media_details.sizes.medium?.source_url  ??
            res.data.media_details.sizes.thumbnail?.source_url
        }
      }
    )
  }

  async getSplashImageURL() {
    return this.client.get(`media`, {
      params: {
        _fields: [
          'id',
          'source_url'
        ],
        slug: 'splash',
        tax_relation: 'AND',
      }
    }).then( res => {
			return res.data[0]
    })
  }
}
