import {CardMedia} from '@mui/material'
import { useContext } from 'react'
import {LessonContext} from '../pages/LessonPlan/context'

export const ProfilePicture = ({ sx }) => {
  const { fellow } = useContext(LessonContext)

  return <CardMedia
    sx={{
      borderRadius: '50%',
      height: 73,
      width: 73,
      marginRight: t => t.spacing(2),
      ...sx
    }}
    component='img'
    alt={fellow.featured_media.alt}
    height={100}
    width={100}
    image={fellow.featured_media.sizes.thumbnail.source_url}
  />
}

